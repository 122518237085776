.logo-container {
    width: 100%;
    height: 6px;
    background-color: transparent !important;
}
.logo {
    display: block;
    margin: auto;
    max-width: 320px;
    padding: 10px;
} 

.menu-item  {
    display: inline-block !important;
    font-weight: 600;
    font-size: 14px;
    padding: 15px;
    color: #826a4e !important;
}
.lang-item.seperator{
    padding: 0px;
} 
.menu-item:after {
    display: block !important;
    content: '' !important;
    border-bottom: solid 1px #826a4e !important;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}
.menu-item:hover {
    color: #777 !important;
    background-color: transparent !important;
    text-decoration: none !important;
    cursor: pointer !important;
}
.menu-item:hover:after {transform: scaleX(1);}
.menu-item.from-left:after {transform-origin: 0% 50%;}
.sticky-top.color{background-color: #fffdf6;}

.lang-item {display: inline-block;padding: 5px;color: #826a4e !important;font-size: 14px;font-weight: 600;padding: 15px;}
.lang-item:after {display: block !important;content: '' !important;border-bottom: solid 1px #826a4e !important;transform: scaleX(0);transition: transform 250ms ease-in-out;}
.lang-item:hover{color:#777;text-decoration:none;cursor: pointer !important;}
.lang-item:hover:after { transform: scaleX(1); }
.lang-item.from-left:after{  transform-origin:  0% 50%; }

.b2b-link {color: #826a4e !important;font-size: 14px;font-weight: 600;padding: 15px;text-decoration: none;display: inline-block;}
.b2b-link:after {display: block !important;content: '' !important;border-bottom: solid 1px #826a4e !important;transform: scaleX(0);transition: transform 250ms ease-in-out;}
.b2b-link:hover{color:#777;text-decoration:none;}
.b2b-link:hover:after { transform: scaleX(1); }
.b2b-link.from-left:after{  transform-origin:  0% 50%; }
