.contact_p{
    font-size: 3.5em;
    font-weight: 600;
    color: #836b4f;
}
.contact_inline{
    display: inline-block;
}
.contact_msg{
    height: 150px !important;
}
.textarea-addon {
    border-radius: 0px !important;
    border-color: #836b4f !important;
    background-color: #fffdf7 !important;
    color: #836b4f !important;
}
.contact_text_up {
    margin-bottom: 30px;
    padding: 5px;
    color: #836b4f;
}
.contact_text_up_1 {
    font-weight: 600;
}
.contact_label {
    font-weight: 100;
    font-size: 0.9em;
}
.contact_chck{
    margin-left: 5% ;
}
.btn{
    color: #ffffff !important;
    background-color: #826a51 !important;
    border: 0px !important;
    border-radius: 0px !important;
}