.carousel-control-next-icon {
    background-image: url("../../../../../public/assets/common/right-arrow.svg") !important;
    margin-left: 25%;
}

.carousel-control-prev-icon {
    background-image: url("../../../../../public/assets/common/left-arrow.svg") !important;
    margin-right: 25%;
}
.carousel-indicators {
    bottom:-40px !important;
}
.carousel-indicators li {
    display: inline-block !important;
    width: 15px !important;
    height: 15px !important;
    margin: 3px !important;
    text-indent: 0 !important;
    cursor: pointer !important;
    border: none !important;
    border-radius: 50% !important;
    background-color: #9c8769 !important;
}
.shop_img_vertical{
    vertical-align: text-bottom!important;
}
.remove-indicator .carousel-indicators{
    display: none !important;
}
.carousel_center{
    text-align: center;
    margin: 15px;
}
.carousel_img{
    max-height: 430px;
}
.img_size{
    max-width:50% !important;
}
.img_size_right{
    margin-right: 1%;
}
.carousel-inner{
    text-align:center!important;
}