.socail_footer {
    width: 150px;
    float: right;
    margin-top: 25px;
    margin-right: 50px;
    font-weight: 600;
    color: #9c8769;
}
.socail_icon_footer {
    max-width: 40px;
}