.estore_title {
    text-align: center;
    border-bottom: 1px solid #836b4f;
    color: #836b4f;
    font-size: 1.2em;
    padding-bottom: 10px;
    margin-top: -17px;
    font-family: 'Raleway',sans-serif!important;
    font-weight: 100;
}
.estore_a{
    text-decoration: none!important;
}
.thumbnail{
    display: block !important;
    max-width: 100% !important;
    
    margin-bottom: 20px !important;
    padding:4px !important;
}
.div_pad{
    padding: 4px !important;
    margin-bottom: 20px !important;
}