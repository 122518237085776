.thumbnail{
    display: block !important;
    max-width: 100% !important;
    /* yeni 
    max-height: 100%;
    min-height: 200px;
    object-fit: scale-down;
    overflow: visible;
    /* yeni */
    height: auto !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-bottom: 20px !important;
    padding:15px !important;
}