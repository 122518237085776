@import url("~animate.css/animate.min.css");
.row {
  margin-right: 0px;
}
.collection_p {
  color: #826a4e;
  font-size: 6vh;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-weight: 100 !important;
  text-align: left;
}
.img_height {
  max-height: 480px;
}

.collection_strong {
  color: #826a4e;
  font-size: 1.7em;
  font-family: "Raleway", sans-serif;
}
.collection_text {
  margin-top: 2%;
  margin-left: 7%;
  margin-bottom: -1%;
}

@media only screen and (max-width: 376px) {
  .img_height {
    max-height: 353px;
  }
  .collection_p {
    font-size: 3vh;
  }
}
@media only screen and (min-width: 800px) {
  .img_height {
    max-height: 353px;
  }
}

@media only screen and (max-width: 801px) {
  .img_height {
    max-height: 353px;
  }
  .collection_p {
    font-size: 5vh;
  }
}
@media only screen and (max-width: 1200px) {
  .img_height {
    max-height: 480px;
  }
}
@media only screen and (min-width: 1201px) {
  .img_height {
    max-height: 480px;
  }
}
@media only screen and (max-width: 1800px) {
  .img_height {
    max-height: 403px;
  }
}
