.product_list_title
{
    text-align: center;
    border-bottom: 1px solid #836b4f;
    color: #836b4f;
    font-size: 1.2em;
    padding-bottom: 10px;
    margin-top: -17px;
    font-family: 'Raleway',sans-serif!important;
    font-weight: 100;
}

.product_list_a {
    text-decoration: none!important;
}
    