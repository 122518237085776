.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.close_ {
    text-align: right;
    margin-bottom: 25px;
}
.thumbnail {
    display: block !important;
    max-width: 100% !important;
    margin-bottom: 20px !important;
    padding: 4px !important;
    /* height: auto !important;
    margin-right: auto !important;
    margin-left: auto !important; */
}
.wrapper {
    margin: 0 auto;
    width: 1150px;
}
.drift-demo-trigger {
    width: 40%;
}
.detail{
    position: relative;
    width: 55%;
    margin-left: 5%;
    float: left;
    height: 300px;
    z-index: 999999;
}
.product_detail_other_img {
    margin-top: 15px !important;
    margin-left: -18px !important;
}
.product_detail_left {
    color: #826a51 !important;
    text-align: left !important;
    font-size: 1.2em !important;
    font-weight: 600 !important;
}

.product_detail_code {
    margin-top: 18px;
}
.product_detail_compos {
    margin-top: 10px;
}
.product_detail_price {
    margin-top: 15px !important;
    background-color: #826a51 !important;
    color: #fff !important;
    text-align: center !important;
    padding: 10px !important;
    margin-left: 15px !important;
    width: 75px !important;
    font-weight: normal !important;
}
.product_detail_beden {
    margin-top: 40px;
}
.buy_alan {
    margin-top: 150px;
    margin-bottom: 70px;
}
.buy_now {
    width: 150px !important;
    height: 75px !important;
    background-color: #836b4f !important;
    text-align: center !important;
    color: #fff !important;
    padding: 13px 30px 13px 30px !important;
    text-decoration: none !important;
    font-weight: normal !important;
}
.beden_detail {
    margin-bottom: 40px !important;
    border: 1px solid #836b4f;
    background-color: #fff;
    text-align: center;
    color: #836b4f;
    padding: 8px;
    margin: 1px;
    font-size: 0.9em;
}